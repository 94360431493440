@import "https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap";
.main {
  background-image: url("bg.4ddea24e.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  min-height: 100vh;
  display: flex;
}

header {
  padding-top: 30px;
}

header nav {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

header .logo {
  width: 465px;
}

header .logo svg {
  -o-object-fit: cover;
  object-fit: cover;
  max-width: 100%;
  height: auto;
}

header .social-icon a {
  color: #fff;
  border: 1px solid #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  font-size: 20px;
  text-decoration: none;
  transition: all .3s linear;
  display: flex;
}

header .social-icon a:hover {
  background-color: #ff3251;
  border-color: #ff3251;
}

.has-line {
  padding-left: 100px;
  position: relative;
}

.has-line:before {
  content: "";
  background-color: #333;
  width: 88px;
  height: 2px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.form-group {
  margin-top: 30px;
  position: relative;
}

.form-group input {
  border: 1px solid #b1b6c5;
  border-radius: 40px;
  width: 710px;
  height: 70px;
  padding-left: 30px;
  transition: all .3s linear;
}

.form-group input:focus {
  border-color: #ff3251;
}

.form-group button {
  color: #fff;
  background-color: #ff3251;
  border: 1px solid #ff3251;
  border-radius: 40px;
  width: 175px;
  height: 70px;
  font-size: 20px;
  font-weight: 500;
  position: absolute;
  top: 0;
  right: 145px;
}

.newsletter-area {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.screen-shots {
  width: 40%;
}

@media (width <= 991px) {
  .screen-shots {
    width: 50%;
  }
}

@media (width <= 420px) {
  .main {
    background-image: none !important;
    display: block !important;
  }

  header {
    padding-top: 15px !important;
  }

  .form-group input {
    width: 100%;
    height: 50px;
  }

  .form-group button {
    width: 130px;
    height: 50px;
    right: 0;
  }

  .has-line {
    text-align: center;
    padding: 0;
  }

  .has-line:before {
    display: none;
  }

  h1 {
    text-align: center;
  }

  .social-icon a {
    color: #001141 !important;
    border-color: #001141 !important;
    width: 35px !important;
    height: 35px !important;
  }

  header .logo {
    width: 140px;
  }

  .newsletter-area {
    margin-bottom: 50px;
    padding-top: 150px;
  }
}

@media only screen and (width >= 420px) and (width <= 574px) {
  .main {
    background-image: none !important;
    display: block !important;
  }

  header {
    padding-top: 15px !important;
  }

  .form-group input {
    width: 100%;
    height: 50px;
  }

  .form-group button {
    width: 130px;
    height: 50px;
    right: 0;
  }

  .has-line {
    text-align: center;
    padding: 0;
  }

  .has-line:before {
    display: none;
  }

  h1 {
    text-align: center;
  }

  .social-icon a {
    color: #001141 !important;
    border-color: #001141 !important;
    width: 35px !important;
    height: 35px !important;
  }

  header .logo {
    width: 150px;
  }

  .newsletter-area {
    margin-bottom: 50px;
    padding-top: 150px;
  }
}

@media (width >= 575px) and (width <= 767px) {
  .main {
    background-image: none !important;
    display: block !important;
  }

  header {
    padding-top: 15px !important;
  }

  .form-group input {
    width: 100%;
    height: 50px;
  }

  .form-group button {
    width: 130px;
    height: 50px;
    right: 0;
  }

  .has-line {
    text-align: center;
    padding: 0;
  }

  .has-line:before {
    display: none;
  }

  h1 {
    text-align: center;
  }

  .social-icon a {
    color: #001141 !important;
    border-color: #001141 !important;
    width: 35px !important;
    height: 35px !important;
  }

  header .logo {
    width: 160px;
  }

  .newsletter-area {
    margin-bottom: 50px;
    padding-top: 150px;
  }
}

@media (width >= 768px) and (width <= 991px) {
  .main {
    background-image: none !important;
  }

  .social-icon a {
    color: #001141 !important;
    border-color: #001141 !important;
  }

  .form-group input {
    width: 100%;
    height: 55px;
  }

  .form-group button {
    height: 55px;
    right: 0;
  }

  .has-line {
    text-align: center;
    padding: 0;
  }

  .has-line:before {
    display: none;
  }

  h5 {
    font-size: 18px !important;
  }

  h1 {
    text-align: center;
    margin: 0 auto;
    max-width: 540px !important;
    font-size: 60px !important;
    line-height: 68px !important;
  }
}

@media (width >= 992px) and (width <= 1200px) {
  .form-group button {
    height: 60px;
    right: 0 !important;
  }

  .form-group input {
    width: 90%;
    height: 60px;
  }
}

@media (width >= 1201px) and (width <= 1399px) {
  .form-group button {
    right: 0 !important;
  }

  .form-group input {
    width: 90%;
  }
}

body {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

h1, h5 {
  color: #001141;
}

h1 {
  max-width: 820px;
  font-size: 94px;
  font-weight: 700;
  line-height: 102px;
}

h5 {
  font-size: 22px;
  font-weight: 500;
}

@media (width <= 767px) {
  h1 {
    font-size: 32px;
    line-height: 40px;
  }

  h5 {
    font-size: 16px;
  }
}

@media (width >= 992px) and (width <= 1200px) {
  h1 {
    font-size: 46px;
    line-height: 44px;
  }

  h5 {
    font-size: 20px;
  }
}

* {
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

img {
  vertical-align: middle;
  border: 0;
  outline: 0;
  max-width: 100%;
}

body {
  position: relative;
}
/*# sourceMappingURL=index.b665b071.css.map */
